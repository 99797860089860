import { createApp } from 'vue'
import App from './App.vue'
import '@/assets/hz_general.css';
import '@/assets/hz_local.css';
import router from "@/routes";
import store from "@/store";
import sup from "@/support/support";
const Vue = createApp(App);

Vue.config.globalProperties.sup = sup; //Support objekt pro uziti ve vsech komponentach
Vue
    .use(store)
    .use(router)
    .mount('#app')

store.dispatch('watchOnlineStatus');